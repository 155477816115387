import { Provider } from '@angular/core';
import { LogsInitConfiguration, datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { environment } from 'environment';
import { followCursor } from 'tippy.js';

const INITIALIZE_DATADOG_ON_LOCAL = false; // default: false

export namespace TippyIntegration {
	export const tooltipVariationLightTheme = Object.assign({}, tooltipVariation, {
		theme: 'light',
		interactive: false,
	}) as typeof tooltipVariation;

	export const tooltipVariationLightThemeWithArrow = Object.assign({}, tooltipVariationLightTheme, {
		arrow: true,
	}) as typeof tooltipVariation;

	export const popperVariationLargeOffset = Object.assign({}, popperVariation, {
		interactive: false,
		offset: [0, 90],
	}) as typeof tooltipVariation;

	export const popperVariationFollowCursor = Object.assign({}, popperVariation, {
		followCursor: true,
		interactive: false,
		offset: [0, 90],
	}) as typeof tooltipVariation;

	export function getTippyConfig(): Provider {
		return provideTippyConfig({
			defaultVariation: 'tooltip',
			plugins: [
				followCursor,
			],
			variations: {
				tooltip: TippyIntegration.tooltipVariationLightTheme,
				tooltipWithArrow: TippyIntegration.tooltipVariationLightThemeWithArrow,
				tooltipDark: tooltipVariation,
				popper: popperVariation,
				popperLargeOffset: popperVariationLargeOffset,
				popperFollowCursor: popperVariationFollowCursor,
			},
			// moveTransition: 'transform 0.2s ease-out',
		});
	}
}

export namespace DatadogIntegration {
	export function initializeDatadog() {
		initializeDatadogLogs();
		initializeDatadogRum();
	}

	function initializeDatadogLogs() {
		const initParams: LogsInitConfiguration = {
			clientToken: environment.datadog.clientToken,
			site: environment.datadog.site,
			env: environment.getEnvString(),
			service: 'white-label-platform',
			forwardErrorsToLogs: false,
			sessionSampleRate: 100,
			allowFallbackToLocalStorage: true,

		};
		if (environment.production) {
			initParams.forwardErrorsToLogs = true;
		}
		if (environment.kind !== 'local' || INITIALIZE_DATADOG_ON_LOCAL) {
			datadogLogs.init(initParams);
		}
	}

	function initializeDatadogRum() {
		const initParams: RumInitConfiguration = {
			clientToken: environment.datadog.clientToken,
			applicationId: environment.datadog.applicationId,
			site: environment.datadog.site,
			env: environment.getEnvString(),
			service: 'white-label-platform',
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'mask-user-input',
		};
		if (environment.kind !== 'local' || INITIALIZE_DATADOG_ON_LOCAL) {
			datadogRum.init(initParams);
		}
	}
}
