import { timeStampNow, ErrorSource, getFileFromStackTraceString, initReportObservable } from '@datadog/browser-core';
import { StatusType } from '../logger/isAuthorized';
import { createErrorFieldFromRawError } from '../createErrorFieldFromRawError';
export function startReportCollection(configuration, lifeCycle) {
  var reportSubscription = initReportObservable(configuration, configuration.forwardReports).subscribe(function (rawError) {
    var message = rawError.message;
    var error;
    var status = rawError.originalError.type === 'deprecation' ? StatusType.warn : StatusType.error;
    if (status === StatusType.error) {
      error = createErrorFieldFromRawError(rawError);
    } else if (rawError.stack) {
      message += " Found in ".concat(getFileFromStackTraceString(rawError.stack));
    }
    lifeCycle.notify(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, {
      rawLogsEvent: {
        date: timeStampNow(),
        message: message,
        origin: ErrorSource.REPORT,
        error: error,
        status: status
      }
    });
  });
  return {
    stop: function () {
      reportSubscription.unsubscribe();
    }
  };
}
