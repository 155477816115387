export function createErrorFieldFromRawError(rawError, _a) {
  var _b = _a === void 0 ? {} : _a,
    /**
     * Set this to `true` to include the error message in the error field. In most cases, the error
     * message is already included in the log message, so we don't need to include it again.
     */
    _c = _b.includeMessage,
    /**
     * Set this to `true` to include the error message in the error field. In most cases, the error
     * message is already included in the log message, so we don't need to include it again.
     */
    includeMessage = _c === void 0 ? false : _c;
  return {
    stack: rawError.stack,
    kind: rawError.type,
    message: includeMessage ? rawError.message : undefined,
    causes: rawError.causes,
    fingerprint: rawError.fingerprint,
    handling: rawError.handling
  };
}
