import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SitesClient, Site, SiteConfig, FullSiteConfig } from '@taradel/web-api-client';
import { environment } from 'environment';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SiteConfigService {

	private _site?: Site;
	private _siteConfigs?: SiteConfig[];
	public loading = true;
	public isUS = environment.instance === 'US';

	private readonly sitesClient: SitesClient;

	constructor(http: HttpClient) {
		this.sitesClient = new SitesClient(http, environment.webApiUrl);
	}

	get site(): Site {
		return this._site!;
	}

	get siteId(): number {
		return this._site!.siteId;
	}


	public getFullConfig(): Observable<FullSiteConfig> {
		return this.sitesClient.getFullSiteConfigByUrl(window.location.hostname);
	}

	public async init(): Promise<void> {
		const fullConfig = await this.getFullSiteConfig(window.location.hostname);
		this._site = fullConfig.site;
		this._siteConfigs = fullConfig.configs;

		document.title = this._site!.name ?? '';
		this.loading = false;
	}

	private getFullSiteConfig(url: string): Promise<FullSiteConfig> {
		return lastValueFrom(this.sitesClient.getFullSiteConfigByUrl(url));
	}

	getString(configCat: string, configName: string): string {
		let config = this._siteConfigs?.find(x => x.configCat === configCat && x.configName === configName);
		return config?.configVal ?? '';
	}

	getNumber(configCat: string, configName: string): number {
		const configVal = this.getString(configCat, configName);
		if (configVal !== '') {
			return parseInt(configVal, 10);
		}
		else {
			return 0;
		}
	}

	getBoolean(configCat: string, configName: string): boolean {
		const configVal = this.getString(configCat, configName);
		return (
			configVal.toLowerCase() === 'true' ||
			configVal.toLowerCase() === 'yes' ||
			configVal === '1'
		);
	}
}
