import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SiteConfigService } from './site-config.service';
import { CmsClient, CmsContentType, SiteLanguage, WLProduct, ProductOptionCategory, ProductOption } from '@taradel/web-api-client';
import { environment } from 'environment';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { marked } from 'marked';
import { ContentMap } from '../../core/classes/content-map';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
	private md: any;
	private readonly cmsClient: CmsClient;
	public siteLanguages: SiteLanguage[] = [];
	public currentLanguage = '';
	public currentLanguageUrlPrefix = '';

	constructor(
		http: HttpClient,
		private sanitizer: DomSanitizer,
		private siteConfigService: SiteConfigService) {
		this.cmsClient = new CmsClient(http, environment.webApiUrl);
		this.md = marked.setOptions({});
	}

	public setupSiteLanguages(siteLanguages: SiteLanguage[]) {
		this.siteLanguages = siteLanguages;
		if (this.siteLanguages.length === 0) {
			this.currentLanguage = 'en';
			this.currentLanguageUrlPrefix = '' ;
		}
		if (this.siteLanguages.length === 1) {
			const theOnlySiteLanguage = this.siteLanguages[0];
			this.currentLanguage = theOnlySiteLanguage.language ?? 'en';
			this.currentLanguageUrlPrefix = theOnlySiteLanguage.urlLanguagePrefix ?? '';
		}

		const urlSegments = window.location.pathname.split('/');
		const possibleLanguagePrefix = urlSegments.at(1); // '/sam' will split to ['', 'sam']
		if (possibleLanguagePrefix === undefined || possibleLanguagePrefix.length === 0) {
			this.currentLanguage = 'en';
			this.currentLanguageUrlPrefix = '' ;
		}

		const match = this.siteLanguages.find(siteLanguage => siteLanguage.urlLanguagePrefix === possibleLanguagePrefix);
		this.currentLanguage = match?.language ?? 'en';
		this.currentLanguageUrlPrefix = match?.urlLanguagePrefix ?? '';
	}

	async getSectionContent(language: string, contentPrefix: string): Promise<ContentMap> {
		let contentSet = await lastValueFrom(this.cmsClient.searchCmsContent(this.siteConfigService.siteId, language, contentPrefix));
		let contentMap = new ContentMap();
		for (let key of contentSet) {
			let html = key.content ?? '';
			const contentType = CmsContentType[key.contentKey!.contentType];
			switch (contentType) {
				case CmsContentType.Markdown:
					html = this.md.parse(html) as string;
					contentMap.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.HTML, html) ?? '' as SafeValue);
					break;
				case CmsContentType.Html:
					contentMap.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.HTML, html) ?? '' as SafeValue);
					break;
				case CmsContentType.Text:
					if (key.contentKey?.contentKey?.toLocaleLowerCase().includes('.link')) {
						contentMap.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.URL, html) ?? '' as SafeValue);
					}
					else if (key.contentKey?.contentKey?.toLocaleLowerCase().includes('backgroundimagesource')) {
						contentMap.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.STYLE, 'url(' + html + ')') ?? '' as SafeValue);
					}
					else if (key.contentKey?.contentKey?.toLocaleLowerCase().includes('imagesource')) {
						contentMap.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.URL, html) ?? '' as SafeValue);
					}
					else {
						contentMap.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.HTML, html) ?? '' as SafeValue);
					}
					break;
				default:
					break;
			}
		}

		return contentMap;
	}

	getProductName(product: WLProduct): string {
		if (this.currentLanguage === 'en') {
			return product.name ?? '';
		}
		const language = this.siteLanguages.find(l => l.language === this.currentLanguage);
		return (language?.wlProductLanguages?.find(p => p.baseProductId === product.baseProductId)?.wlProductName ?? product.name) ?? '';
	}

	getCategoryName(optCat: ProductOptionCategory) : string {
		if (this.currentLanguage === 'en') {
			return optCat.name ?? '';
		}
		return optCat.languages?.find(l => l.language === 'fr')?.categoryName ?? (optCat.name ?? '');
	}

	getOptionName(optCat: ProductOptionCategory, option: ProductOption): string {
		if (this.currentLanguage === 'en') {
			return option.name ?? '';
		}
		return optCat.languages?.find(l => l.language === 'fr')?.productOptionLanguages?.find(o => o.optionId === option.optionId)?.optionName ?? (option.name ?? '');
	}

	format(content: SafeValue, ...args: any[]): SafeValue {
		return (content as string).replace(/{(\d+)}/g, (match, index) => args[index] === undefined ? match : args[index]);
	}
}
