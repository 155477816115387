import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { IdentitySettings } from 'environments/environment-type';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	settings: IdentitySettings;

	loggedIn = new EventEmitter<void>();
	loggedOut = new EventEmitter<void>();

	accessToken?: string;
	refreshToken?: string;

	refreshHandle?: ReturnType<typeof setTimeout>;

	profile?: any;
	customerId = 0;
	expiration = new Date(0);

	constructor() {
		this.settings = environment.identity;
		const session = localStorage.getItem('tokenResponse');
		let accessTokenDecoded = false;
		if (session !== null) {
			accessTokenDecoded = this.decodeAccessToken(JSON.parse(session));
		}
		if (accessTokenDecoded) {
			this.loggedIn.emit();
		}
	}

	async login(username: string, password: string): Promise<'success' | Error> {
        try {
            const response = await fetch(`${this.settings.authority}/connect/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams({
                    grant_type: 'password',
                    client_id: window.location.host,
                    username,
                    password,
                    scope: this.settings.scope,
                    response_type: this.settings.responseType
                }),
            });

            const responseObject = await response.json();
            if (responseObject.error !== undefined) {
                return new Error(responseObject.error_description);
            }
            localStorage.setItem('tokenResponse', JSON.stringify(responseObject));

            const accessTokenDecoded = this.decodeAccessToken(responseObject);

            if (accessTokenDecoded) {
                this.loggedIn.emit();
            }
        }
        catch (error: any) {
            return new Error('unknown_error');
        }

        return 'success';
	}

	logout(): void {
		delete this.accessToken;
		delete this.refreshToken;
		delete this.profile;
		this.customerId = 0;
		this.expiration = new Date(0);

		localStorage.removeItem('tokenResponse');

		this.loggedOut.emit();
	}

	async refresh() {
		const response = await fetch(`${this.settings.authority}/connect/token`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: new URLSearchParams({
				grant_type: 'refresh_token',
				client_id: window.location.host,
				refresh_token: this.refreshToken!
			}),
		});

		const responseObject = await response.json();
		if (responseObject.error !== undefined) {
			this.logout();
			return;
		}
		localStorage.setItem('tokenResponse', JSON.stringify(responseObject));

		const accessTokenDecoded = this.decodeAccessToken(responseObject);
		if (!accessTokenDecoded) {
			this.loggedOut.emit();
		}
	}

	isAuthenticated(): boolean {
		return this.expiration > new Date();
	}

	private decodeAccessToken(response: any): boolean {
		if (response.access_token === undefined) {
			return false;
		}

		this.accessToken = `${response.token_type} ${response.access_token}`;
		this.refreshToken = response.refresh_token;

		let base64: string = response.access_token.split('.')[1].replace('-', '+').replace('_', '/');
		this.profile = JSON.parse(atob(base64));
		this.customerId = +this.profile.customerId;
		this.expiration = new Date(this.profile.exp * 1000);

		if (this.refreshHandle !== undefined) {
			clearTimeout(this.refreshHandle);
		}

		// Subtract one minute from expiration to determine when to set a timeout for refresh.
		const expirationInterval = this.expiration.valueOf() - 60 * 1000 - new Date().valueOf();
		if (expirationInterval < 0) {
			this.refresh();
		}
		else {
			this.refreshHandle = setTimeout(() => this.refresh(), expirationInterval);
		}
		return true;
	}
}
