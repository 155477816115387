import { Injectable } from "@angular/core";
import { Logger, datadogLogs } from "@datadog/browser-logs";

export type LogLevel = 'debug' | 'info' | 'warn' | 'error' | 'critical'

export class TLogger {
	private static instance: TLogger;
	private externalLogger: Logger;
	private get logger() {
		return this.externalLogger;
	}
	private oneTimeMessages: Set<string> = new Set();

	constructor(
	) {
		this.externalLogger = datadogLogs.logger;
		TLogger.instance = this;
	}

	/** Gets the current instance of the service, useful if outside of Angular context and can't use dependency injection. */
	static getInstance(): TLogger {
		if (!TLogger.instance) {
			new TLogger();
		}
		return TLogger.instance;
	}

	/** Gets the current instance of the service, useful if outside of Angular context and can't use dependency injection. */
	static get i(): TLogger {
		return TLogger.getInstance();
	}

	/** Logs a given message a maximum of one time per session. */
	once(logLevel: LogLevel, ...params: any) {
		const paramsJoined = params.join('|');
		if (this.oneTimeMessages.has(paramsJoined)) {
			return;
		}
		this.oneTimeMessages.add(paramsJoined);
		this[logLevel](...params);
	}

	// wrapper methods are arranged from least severe to most severe

	/** Severity 1 of 5, for debug purposes. */
	debug(...params: any) {
		console.debug(`[TLogger]`, ...params);
		this.logger.debug(params);
	}

	/** Severity 2 of 7, informational. */
	info(...params: any) {
		console.info(`[TLogger]`, ...params);
		this.logger.info(params);
	}

	/** Alias of info, severity 2 of 5, informational. */
	log(...params: any) {
		return this.info(...params);
	}

	/** Severity 3 of 5, moderate severity. */
	warn(...params: any) {
		console.warn(`[TLogger]`, ...params);
		this.logger.warn(params);
	}

	/** Severity 4 of 5, high severity. */
	error(...params: any) {
		console.error(`[TLogger]`, ...params);
		this.logger.error(params);
	}

	/** Severity 5 of 5, ultra-high severity, hair currently on fire. */
	critical(...params: any) {
		console.error(`[TLogger]`, ...params);
		this.logger.critical(params);
	}

}
